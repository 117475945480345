<template>
    <section>
        <div class="blocker-container">
            <Headline v-bind:headline="this.headline"/>
            <div class="text">{{text}}</div>
            <ContactTel></ContactTel>
        </div>
    </section>
</template>

<script>
    import Headline from './Headline.vue'
    import ContactTel from './ContactTel.vue'

    export default {
        name: 'Blocker',
        components: {
            Headline,
            ContactTel
        },
        props: {
            headline: String,
            text: String,
        }
    }
</script>

<style lang="scss" scoped>
    section {
        background-color: #323232;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 20px 0;
        padding: 10px 0 20px 0;

        > div {
            color: white;
        }
    }

    .blocker-container {
        width: 80%;
    }

    .text {
        letter-spacing: 1.4px;
        margin-bottom: 10px;
    }
</style>