<template>
  <div class="banner" v-if="isMobile">
    <ContactTel></ContactTel>
    <div style="display: flex;" v-on:click="openNavigation">
      <svg fill="#dec850" height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>
    </div>
  </div>
</template>

<script>
  import ContactTel from '../RepeatableElements/ContactTel';

  export default {
    name: 'HeaderMobileBanner',
    components: {
      ContactTel
    },
    props: {
      openNavigation: Function
    },
    methods: {
      isMobile: function () {
        return window.innerWidth < 425;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .banner {
    width: 100%;
    height: 60px;
    background-color: #323232;
    display: flex;
    justify-content: space-around;
    align-items: center;

    > div {
      color: white;
    }

  }

  .mobile-number {
    font-size: 20px;
  }

  .detail-text {
    font-size: 14px;
  }
</style>
