<template>
  <div id="app">
    <div class="general-outline">
      <Header :title="sectionData.header.title" :openNavigation="openNavigation" />
      <MobileNavigation v-if="mobileNavigationOpen" :data="sectionData.navigation" :closeNavigation="closeNavigation"></MobileNavigation>
      <WelcomeSection id="aboutMe" :headline="sectionData.aboutMe.headline" :title="sectionData.aboutMe.title" :paragraph="sectionData.aboutMe.paragraph" :url="sectionData.aboutMe.url" />
      <Blocker id="contact" :headline="sectionData.contact.headline" :text="sectionData.contact.text"></Blocker>
      <ContentSection id="treatment" smallRows :title="sectionData.treatment.title" :content="sectionData.treatment.content"></ContentSection>
      <Divider />
      <ContentSection id="methods" :title="sectionData.method.title" :description="sectionData.method.description" :content="sectionData.method.content"></ContentSection>
      <RentABemer id="rentABemer" :data="sectionData.rentABemer" />
      <Prices id="prices" :data="sectionData.prices"></Prices>
      <Divider />
      <FAQ id="faq" :data="sectionData.faq" />
      <Divider />
      <Imprint id="imprint" />
      <Footer />
      <ToTopButton />
    </div>
  </div>
</template>

<script>
  import Header from './components/Header/Header.vue';
  import Footer from './components/Footer/Footer.vue';
  import Divider from './components/VisualSupportElements/Divider.vue';
  import WelcomeSection from './components/RepeatableElements/WelcomeSection.vue';
  import Blocker from './components/RepeatableElements/Blocker.vue';
  import ContentSection from './components/RepeatableElements/ContentSection.vue';
  import Prices from './components/Prices/Prices.vue';
  import Imprint from './components/Imprint/Imprint.vue';
  import FAQ from './components/FAQ/FAQ.vue';
  import MobileNavigation from './components/Header/MobileNavigation.vue'
  import ToTopButton from './components/ToTopButton.vue';
  import RentABemer from './components/RentABemer/RentABemer';

  export default {
    name: 'app',
    components: {
      RentABemer,
      ContentSection,
      Header,
      Footer,
      Divider,
      WelcomeSection,
      Blocker,
      Prices,
      Imprint,
      FAQ,
      MobileNavigation,
      ToTopButton
    },
    methods: {
      openNavigation: function () {
        this.mobileNavigationOpen = !this.mobileNavigationOpen;
      },
      closeNavigation: function () {
        this.mobileNavigationOpen = false;
      }
    },
    data: function () {
      return {
        mobileNavigationOpen: false,
        sectionData: {
          navigation: {
            targets: [
              {
                id: 'aboutMe',
                name: 'Über mich'
              },
              {
                id: 'contact',
                name: 'Kontakt',
              },
              {
                id: 'treatment',
                name: 'Die Behandlung',
              },
              {
                id: 'methods',
                name: 'Methoden',
              },
              {
                id: 'rentABemer',
                name: 'Bemer Mieten'
              },
              {
                id: 'prices',
                name: 'Preise',
              },
              {
                id: 'faq',
                name: 'FAQ',
              },
              {
                id: 'imprint',
                name: 'Impressum'
              }
            ]
          },
          header: {
            title: 'Pferdeosteopathie und Physiotherapie'
          },
          aboutMe: {
            headline: 'Über mich',
            title: 'Herzlich Willkommen auf meiner Seite!',
            url: require('@/assets/ueber_mich_breiter.jpg'),
            paragraph: 'Mein Name ist Julia Duve, ich bin Pferdeosteopathin &- physiotherapeutin und widme mich mit großer Leidenschaft der Gesunderhaltung von Pferden. Meine Ausbildung habe ich am Fachzentrum für Pferdeosteopathie in Schleswig-Holstein absolviert. Seitdem habe ich dort viele Weiterbildungen besucht unter anderem Dry Needling, Craniosacrale Therapie, Blutegeltherapie uvm. Auch zukünftig sind Weiterbildungen geplant um meine Patienten bestmöglich behandeln zu können. Pferde sind schon seit meiner Kindheit meine große Leidenschaft und nun habe ich mein Hobby zum Beruf gemacht. Ich habe sehr viel Spaß daran ihrem Pferd mit fundiertem Wissen weiterzuhelfen. Die Kombination von verschiedenen Behandlungstechniken erlaubt es mir Ihr Pferd individuell, auf die Bedürfnisse angepasst, zu behandeln. Ich arbeite hauptsächlich mit sanften Techniken wie der Craniosacralen Therapie, so ist es mir möglich dem Pferd ein positives Erlebnis zu verschaffen. Damit habe ich bisher die besten Erfahrungen und Ergebnisse erzielt, denn ich erhalte keine Gegenwehr sondern arbeite mit dem Pferd zusammen. Überwiegend bin ich in Lübeck und Nordwestmecklenburg und Umgebung unterwegs, bei Sammelterminen auch Deutschlandweit. Haben Sie Fragen? Dann zögern Sie nicht mich anzusprechen.'
          },
          contact: {
            headline: 'Kontakt',
            text: 'Zögern Sie nicht mich anzusprechen, ich stehe Ihnen gerne mit Rat und Tat zur Seite.'
          },
          treatment: {
            title: 'Ablauf einer Behandlung',
            content: [
              {
                url: require('@/assets/anamnese.jpg'),
                keyword: 'Anamnese',
                paragraph: 'Die Anamnese ist besonders wichtig, denn hier erfahre ich von Ihnen alle wichtigen Informationen über Ihr Pferd. Wichtige Anhaltspunkte sind unter anderem Alter des Pferdes, Vorerkrankungen, Auffälligkeiten / Besonderheiten, letzter Zahnarzttermin uvm. So kann ich mir einen ersten Eindruck über meinen Patienten verschaffen.'
              },
              {
                url: require('@/assets/aspektion.jpg'),
                keyword: 'Aspektion',
                paragraph: 'Anschließend folgt der Sichtbefund im Seitenbild. Hier verschaffe ich mir ein Bild über das Exterieur des Pferdes. Der „Status“ der Muskulatur lässt sich im Seitenbild sehr gut beurteilen.'
              },
              {
                url: require('@/assets/palpation.jpg'),
                keyword: 'Palpation',
                paragraph: 'Bei der Palpation taste ich ihr Pferd ab um z.B. Schwellungen oder warme Zonen zu erkennen, die auf eine Entzündung hinweisen können.'
              },
              {
                url: require('@/assets/bewegungsanalyse.jpg'),
                keyword: 'Bewegungs-Analyse',
                paragraph: 'Die Bewegungsanalyse gibt sehr viel Aufschluss über die Blockaden / Problematiken des Pferdes. Hierzu betrachte ich das Pferd zuerst an der Hand, im Schritt und Trab auf gerader Linie, in der Volte und im Rückwärtsrichten. Danach folgt die Bewegungsanalyse an der Longe im Schritt, Trab und Galopp. Auch eine Bewegungsanalyse unter dem Reiter kann sinnvoll sein.'
              },
              {
                url: require('@/assets/befundung_der_gelenke.jpg'),
                keyword: 'Befunde der Gelenke',
                paragraph: 'Um den Eindruck über die Blockaden / Problematiken zu vervollständigen folgt die Befundung der Gelenke. Ich untersuche jedes Gelenk einzeln und teste es auf seine maximale Beweglichkeit, hier kann ich im Seitenvergleich Einschränkungen oder Blockaden feststellen.'
              },
              {
                url: require('@/assets/behandlung.jpg'),
                keyword: 'Behandlung',
                paragraph: 'Abschließend folgt die Behandlung ihres Pferdes. Aus verschiedenen osteopathischen und physiotherapeutischen Techniken passe ich meine Behandlung individuell an Ihr Pferd an. Um ein maximales Ergebnis zu erzielen können Sie mich nach passenden Übungen zum selbstausführen Fragen. Auf Wunsch erstelle ich Ihnen auch gerne einen Trainingsplan.'
              },
            ]
          },
          method: {
            title: 'Behandlungs- methoden',
            description: 'Warum/Wann ist eine osteopathische Behandlung sinnvoll?\n' +
              'Du hast das Gefühl dass es irgendwo klemmt, alles nicht so geschmeidig ist wie sonst oder du fühlst dass dein Pferd sein volles Potential nicht ausschöpfen kann? Dein Pferd hat eine chronische Erkrankung? Oder du möchtest deinem Pferd einfach nur etwas Gutes tun?\n' +
              'Genau dann sollte man über eine osteopathische & -physiotherapeutische Behandlung nachdenken.\n' +
              'Aus einer Kombination von verschiedenen Techniken wie z.B. Massage, Dehnung, Mobilisation uvm. ist es mir möglich Blockaden zu lösen und das ursprüngliche Bewegungsausmaß wiederherzustellen. Dies sorgt für ein besseres Wohlbefinden Ihres Pferdes und auch Sie werden davon profitieren.\n',
            content: [
              {
                url: require('@/assets/osteopathie_und_physiotherapie.jpg'),
                keyword: 'Osteopathie und Physiotherapie',
                paragraph: 'Die Osteopathie ist eine ganzheitliche Therapieform, die mit manuellen Techniken arbeitet. Es werden Bewegungseinschränkungen und Dysfunktionen festgestellt. Ziel der Osteopathie ist die Wiederherstellung der Körpermechanik und die Anregung der Selbstheilungskräfte des Körpers. \n' +
                  'Durch die Kombination von physiotherapeutischen Behandlungsmethoden wie z.B. der Massage oder Dehnungsübungen können die Bewegungseinschränkungen und Dysfunktionen verbessert und behoben werden.\n'
              },
              {
                url: require('@/assets/craniosacrale_therapie.jpg'),
                keyword: 'Craniosacrale Therapie',
                paragraph: 'Die Craniosacrale Therapie ist eine ganzheitliche Therapieform, die sehr sanft arbeitet. Mit verschiedenen sehr sanften Zug und Druck Techniken wird der Körper angeregt sich in seine ursprüngliche Form zurück zu bewegen und seine Selbstheilungskräfte zu aktivieren. So können Blockaden und Verspannungen sehr sanft und nachhaltig gelöst werden. Die Craniosacrale Therapie ist bei den Pferden sehr beliebt und sie können sich gut dabei entspannen. Diese Therapieform ist mein persönlicher Favorit, ich nutze diese Technik sehr oft und ich kann schon einige Erfolge verbuchen.'
              },
              {
                url: require('@/assets/dry_needling.jpg'),
                keyword: 'Dry Needling',
                paragraph: 'Die Behandlungsmethode Dry Needling ist eine spezielle Technik zur Behandlung myofaszialer Triggerpunkte (Schmerzpunkte). Chronische oder akute myofasziale Schmerzen können mit der Behandlung durch sterile Einwegakupunkturnadeln sehr gut behandelt werden und Triggerpunkte können damit ausgeschaltet werden. Diese Technik nutze ich als Ergänzung zu der normalen osteopathischen- und physiotherapeutischen Behandlung.'
              },
              {
                url: require('@/assets/kinesio_aping.jpg'),
                keyword: 'Kinesio Taping',
                paragraph: 'Die Kinesio Tapes können genutzt werde um eine bessere Muskelfunktion, Gelenkfunktion, Schmerzreduktion und einen verbesserten Lymphfluss zu erzielen. Die Tapes werden auf das Fell der Pferde geklebt, das Fell und die Haut werden somit angehoben und die darunterliegenden Meridiane können wieder störungsfrei fließen, die Selbstheilung wird angeregt. Tapeanlagen nutze ich als Ergänzung an eine osteopathische- und physiotherapeutische Behandlung.'
              },
              {
                url: require('@/assets/blutegeltherapie.jpg'),
                keyword: 'Blutegeltherapie',
                paragraph: `<p>Die Blutegeltherapie ist eine alternative Behandlungsmethode die für viele akute und auch chronische Erkrankungen indiziert ist. Beispiele von Erkrankungen bei der eine Blutegeltherapie sinnvoll ist:</p>
                                    <ul>
                                        <li>akute und chronische Hufrehe</li>
                                        <li>akute Arthritis und chronische Arthrose</li>
                                        <li>Verletzungen des Sehnen- und Bänderapparates</li>
                                        <li>Ekzeme</li>
                                        <li>uvm</li>
                                    </ul>
                                    <p>Wenn ein Blutegel angesetzt wird und Blut saugt, gibt er gleichzeitig seinen wertvollen Speichel in die Wunde ab. Der Speichel der Blutegel beinhaltet viele verschiedene Enzyme die unter anderem schmerzlindernd und entzündungshemmend wirken. Die Bluttherapie ist eine eigenständige Behandlung und gehört nicht zu der normalen osteopathischen- und physiotherapeutischen Behandlung.
                                    Wenn Sie an einer Blutegeltherapie interessiert sind nehmen Sie gerne Kontakt auf und ich erstelle Ihnen ein Angebot.</p>`
              },
              {
                url: require('@/assets/bemer_behandlung.jpg'),
                keyword: 'Bemer Behandlung',
                paragraph: 'Ich bin für euch als Bemer Partnerin im Einsatz! Die physikalische Gefäßtherapie Bemer verbessert die Mikrozirkulation, die sich im Bereich der kleinsten Blutgefäße abspielt. In diesem Bereich finden die Stoffwechselprozesse statt, Zellen werden versorgt und Stoffwechselendprodukte werden entsorgt. Ihrem Pferd kann es nur rundum gut gehen, wenn die Mikrozirkulation einwandfrei funktioniert. Die Bemer Therapie ist daher eine gute Ergänzung zu einer osteopathischen und physiotherapeutischen Behandlung. Gerne nutze ich die Bemer Therapie im Anschluss an meine Behandlung, um ein noch besseres Ergebnis zu erzielen. Zusätzlich können sie den Bemer bei mir mieten oder kaufen.'
              },

            ]
          },
          rentABemer: {
            headline: 'Bemer Mieten',
            stageText: 'Haben Sie Interesse an der Bemer Therapie, dann bin ich Ihr Ansprechpartner. Fragen Sie mich gerne für einen Termin an um sich von der Bemer Therapie zu überzeugen. Alternativ können Sie den Bemer bei mir mieten.',
            bottomText: `<p style="font-size: 9px">Effects of BEMER physical vasculoar therapy in horses under training. A randomized, controlled double blind study. Francesca Daia, Emanuela Dalla Costaa, Alessia Giordanoa, c, Eugenio U.L. Heinzla, Piermario Giongob, Gracia Pagnozzi, Simona Cannasa, Giulietta Minozzia, Michela Mineroa, Dipartimento di Medicina Veterinaria, Universita di Milano (in publication)*
                          Anwendungsbeobachtung Pferdeklinik Mühlen, Dr. Genn 2017**</p>
                          <p>Sie sind interessiert am Kauf eines Bemer Horse Sets, dann bin ich auch hier ihr Ansprechpartner.</p>
                          <p><b>Mehr erfahren: <a style="color: #FF6400;" href="https://pferdetherapieduve.bemergroup.com" target="_blank">pferdetherapieduve.bemergroup.com</a></b></p>`,
            url: require('@/assets/bemer_logo.svg'),
            gridItems: {
              left: {
                title: 'Über den Bemer:',
                img: null,
                paragraph: `<p>Der Bemer unterstützt Heilungsprozesse, verbessert die Mikrozirkulation,  fördert die Regeneration und kann präventiv eingesetzt werden. Es optimiert die Losgelassenheit und ermöglicht somit ein effizienteres Training. Ihr Pferd wird nicht nur vor, sondern auch nach Belastungen leichter entspannen und besser regenerieren.</p>
                  <p><b>Was für Folgen hat eine gestörte Mikrozirkulation?</b></p>
                  <ul>
                    <li>Verminderte Leistungsfähigkeit</li>
                    <li>Unzureichende Regeneration in der Ruhephase</li>
                    <li>Erhöhte Infektanfälligkeit und verminderte –abwehr</li>
                    <li>Erhöhtes Risiko von Wundheilungsstörungen</li>
                  </ul>
                  <p><b>Wie kann sich die Bemer Therapie positiv auswirken?</b></p>
                  <ul>
                    <li>Verbesserung der Mikrozirkulation</li>
                    <li>Fördert die parasympathischen Vorgänge im Organismus und somit Regeneration und Entspannung*</li>
                    <li>Verbesserung der Losgelassenheit für optimales Training*</li>
                    <li>Erhöhung der Leistungsfähigkeit</li>
                    <li>Prävention und Steigerung der Infektabwehr</li>
                    <li>Besserer Narkoseverlauf während einer Operation**</li>
                    <li>Schnellere Regeneration nach einer Operation. Pferde bleiben länger in der Seitenlage liegen, entspannen besser und stehen sicherer auf**</li>
                  </ul>`
              },
              right: {
                title: 'Ablauf einer Bemer-Miete:',
                img: require('@/assets/bemer_rumsitzen.jpg'),
                paragraph: `<p>Aktuell können Sie den Bemer hier ausleihen:</p>
                  <ul>
                    <li>Lübeck</li>
                    <li>Nord-West-Mecklenburg</li>
                    <li>und Umgebung</li>
                  </ul>
                  <a style="color: #FF6400; font-size: 13px;" href="#contact"><b>Miete in andere Regionen anfragen</b></a>.
                  <p>Melden Sie sich gern bei mir zum Verfügbarkeits-Check der Geräte, der passende Zeitraum wird dann für Sie reserviert.</p>
                  <p>Bei Mietbeginn wird ein Termin empfohlen für eine Einleitung in die Einstellungsmöglichkeiten und Anwendung des Bemers, maßgeschneidert auf die Bedürfnisse ihres Pferdes.</p>`
              }
            }
          },
          prices: {
            title: 'Preise',
            priceBoxes: [
              {
                priceTitle: 'Erstbehandlung',
                amount: '120,00 €*',
                description: 'Eine Behandlung dauert etwa 1 - 1,5 Std.',
                reminder: '*Exkl. Sonderbehandlungen wie die Verwendung von Blutegeln'
              },
              {
                priceTitle: 'Nachbehandlung',
                amount: '80,00 €*',
                description: 'Innerhalb von 8 Wochen',
                reminder: '*Exkl. Sonderbehandlungen wie die Verwendung von Blutegeln'
              },
              {
                priceTitle: 'Anfahrt',
                amount: '0,30 €/km',
                reminder: null
              },
              {
                priceTitle: 'Bemer Verleih',
                amount: '120 €/Woche',
                reminder: null
              }
            ]
          },
          faq: {
            title: 'FAQ',
            faqBoxes: [
              {
                question: 'Wann darf ich mein Pferd wieder reiten?',
                answer: 'Da jedes Pferd individuell auf die Behandlung anspricht empfehle ich Ihnen ihrem Pferd eine Ruhepause zu gönnen. Am Folgetag der Behandlung wäre es sinnvoll einen „freien“ Tag einzuplanen.'
              },
              {
                question: 'Darf mein Pferd nach der Behandlung auf die Wiese?',
                answer: 'Ja! Sowohl vor als auch nach dem Termin ist es absolut wünschenswert.'
              },
              {
                question: 'Muss ich etwas vorbereiten?',
                answer: 'Nein, das ist nicht notwendig. Bitte führen Sie alles so aus wie an jedem anderen Tag auch.'
              },
              {
                question: 'Sind Wochenendtermine möglich?',
                answer: 'Natürlich stehe ich Ihnen auch am Wochenende zur Verfügung. Vereinbaren sie einfach einen Termin mit mir.'
              },
              {
                question: 'Wann ist ein Folgetermin notwendig?',
                answer: 'Einen Folgetermin können wir individuell ausmachen, dafür gibt es keine pauschale Antwort, da es von der Problematik des Pferdes abhängig ist. In den meisten Fällen werden Sie es selbst merken wann ein Folgetermin sinnvoll wäre.'
              },
            ]
          }

        }
      }
    }
  };
</script>

<style>
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    font-family: "Roboto Condensed";
  }

  .general-outline {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 16px;
    max-width: 1080px;
  }

  button {
    box-sizing: border-box;
    margin: 10px 10px 0 0;
    border: none;
    border-radius: 4px;
    padding: 0 16px;
    min-width: 64px;
    height: 36px;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    color: black;
    background-color: rgb(222, 200, 80);
    font-size: 14px;
    font-weight: bold;
    line-height: 36px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.2s;
  }

  @media screen and (max-width: 1080px) {
    .general-outline {
      width: 100vw;
    }

    button {
      transform: scale(0.8);
    }
  }
</style>
