<template>
  <div style="display: flex; justify-content: flex-end">
  <div class="navigation" v-if="!isMobile">
    <span v-on:click="scrollTo('aboutMe')">ÜBER MICH</span>
    <span v-on:click="scrollTo('contact')">KONTAKT</span>
    <span v-on:click="scrollTo('treatment')">BEHANDLUNG</span>
    <span v-on:click="scrollTo('methods')">MEHTODEN</span>
    <span v-on:click="scrollTo('rentABemer')">BEMER</span>
    <span v-on:click="scrollTo('prices')">PREISE</span>
    <span v-on:click="scrollTo('faq')">FAQ</span>
    <span v-on:click="scrollTo('imprint')">IMPRESSUM</span>
  </div>
  </div>
</template>

<script>
  import ScrollHelper from '../ScrollHelper.ts';

  export default {
    name: 'Navigation',
    data: function () {
      return {
        isMobile: window.innerWidth < 720
      };
    },
    methods: {
      scrollTo: ScrollHelper.scrollTo
    }
  };
</script>

<style lang="scss" scoped>
  .navigation > span {
    margin: 4px 15px;
    font-weight: 500;
    color: white;
    letter-spacing: 1.4px;
    position: relative;  
    cursor: pointer;

    &:hover {
      color: #dec850;
      font-weight: bold;
    }

    .active {
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 80%;
        background-color: rgb(220, 200, 80);
        bottom: -4px;
        left: 0;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: 600px;
    margin: 10px;
  }

</style>
