<template>
    <section>
        <div class="content-container">
            <Headline headline="Impressum"></Headline>
            <div class="box-container">
                <div class="box"><b>Die Vorgaben von § 5 TMG sind durch die folgenden Angaben erfüllt:</b></div>
                <div class="box">
                    Julia Duve<br>
                    Hornsdorfer Weg 7<br>
                    23823 Seedorf<br>
                    Deutschland<br>
                </div>
                <div class="box">
                    <b>Kontakt:</b><br>
                    Tel.: +49 (0) 170 56 95 653<br>
                    E-Mail: info@pferdetherapie-duve.com<br>
                </div>
                <h3>Ausschluss der Haftung:</h3>
                <div class="box">
                    <b>1. Haftung für Inhalte</b><br>
                    Der Inhalt meiner Internetseite wurde mit größtmöglicher Sorgfalt erstellt. Ich übernehme jedoch keine Gewähr dafür, dass dieser Inhalt richtig, vollständig, und aktuell ist und zudem noch gefällt. Gemäß § 7 Abs. 1 TMG bin ich für den Inhalt verantwortlich, selbst wenn dieser wurde bestellt.
                    Gemäß den §§ 8, 9 und 10 TMG ist für mich keine Verpflichtung gegeben, dass ich Informationen von Dritten, die übermittelt oder gespeichert wurden, überwache oder Umstände erhebe, die Hinweise auf nicht rechtmäßige Tätigkeiten ergeben.
                    Davon nicht berührt, ist meine Verpflichtung zur Sperrung oder Entfernung von Informationen, welche von den allgemeinen Gesetzen herrührt.
                    Ich hafte allerdings erst in dem Moment, in dem ich von einer konkreten Verletzung von Rechten Kenntnis bekomme. Dann wird eine unverzügliche Entfernung des entsprechenden Inhalts vorgenommen.
                </div>
                <div class="box">
                    <b>2. Haftung für Links</b><br>
                    Meine Internetseite enthält Links, die zu externen Internetseiten von Dritten führen, auf deren Inhalte ich jedoch keinen Einfluss habe. Es ist mir daher nicht möglich, eine Gewähr für diese Inhalte zu tragen.
                    Die Verantwortung dafür hat immer der jeweilige Anbieter/Betreiber der entsprechenden Internetseite. Ich überprüfe die von mir verlinkten Internetseiten zum Zeitpunkt der Verlinkung auf einen möglichen Rechtsverstoß in voller Breite.
                    Es kann mir jedoch, ohne einen konkreten Anhaltspunkt, nicht zugemutet werden, ständig die verlinkten Internetseiten inhaltlich zu überwachen. Wenn ich jedoch von einer Rechtsverletzung Kenntnis erlange, werde ich den entsprechenden Link unverzüglich entfernen, das kann ich machen.
                </div>
                <div class="box">
                    <b>3. Urheberrecht</b><br>
                    Die auf meiner Internetseite enthaltenen Inhalte sind, soweit möglich, urheberrechtlich geschützt. Es bedarf einer schriftlichen Genehmigung des Erstellers für denjenigen, der die Inhalte vervielfältigt, bearbeitet, verbreitet oder nützt.
                    Das Herunterladen und Kopieren meiner Internetseite ist sowohl für den privaten als auch den kommerziellen Gebrauch von mir schriftlich zu gestalten. Ich weise darauf hin, dass ich hinsichtlich der Inhalte auf meiner Internetseite, soweit sie nicht von mir erstellt worden sind, das Urheberrecht von Dritten jederzeit beachtet hatte.
                    Wenn Sie mir mitteilen würden, dass Sie trotzdem eine Urheberrechtsverletzung gefunden haben, würde ich das sehr schätzen. Dann kann ich den entsprechenden Inhalt sofort entfernen und würde damit das Urheberrecht nicht mehr verletzen.
                </div>

                <p><strong><big>Datenschutzerklärung</big></strong></p>
                <p><strong>Allgemeiner Hinweis und Pflichtinformationen</strong></p>
                <p><strong>Benennung der verantwortlichen Stelle</strong></p>
                <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                <p><span id="s3-t-ansprechpartner">Julia Duve</span><br><span id="s3-t-strasse">Hornsdorfer Weg 7</span><br><span id="s3-t-plz">23823</span> <span id="s3-t-ort">Seedorf</span></p><p></p>
                <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>

                <p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p>
                <p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>

                <p><strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong></p>
                <p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>

                <p><strong>Recht auf Datenübertragbarkeit</strong></p>
                <p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

                <p><strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong></p>
                <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>

                <p><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
                <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>

                <p><strong>Google Web Fonts</strong></p>
                <p>Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
                <p>Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem Server von Google in den USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung und Übertragung unseres Webauftritts.</p>
                <p>Das Unternehmen Google ist für das us-europäische Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten.</p>
                <p>Einzelheiten über Google Web Fonts finden Sie unter: <a href="https://www.google.com/fonts#AboutPlace:about">https://www.google.com/fonts#AboutPlace:about</a> und weitere Informationen in den Datenschutzbestimmungen von Google: <a href="https://policies.google.com/privacy/partners?hl=de">https://policies.google.com/privacy/partners?hl=de</a></p>
                <p><small>Quelle: Datenschutz-Konfigurator von <a href="http://www.mein-datenschutzbeauftragter.de" target="_blank">mein-datenschutzbeauftragter.de</a></small></p>
                <div class="box">
                    <b>Bildnachweis</b><br>
                    Fotografien: Gina Lange & Tatjana Junge
                    Icons: <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Headline from '../RepeatableElements/Headline';

    export default {
        name: 'Imprint',
        components: {
            Headline
        }
    }
</script>

<style lang="scss" scoped>
    a {
        color: #dec850;
    }
    section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .content-container {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        margin-bottom: 10px;
    }

    .box {
        margin-bottom: 10px;

        &-container {
            align-self: flex-start;
			word-break: break-word;
        }
    }
</style>
