<template>
    <section>
        <div class="grid-container">
            <div v-for="(target, index) in data.targets" :key="index">
                <div v-on:click="scrollTo(target.id)" class="entry">{{target.name.toUpperCase()}}</div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import ScrollHelper from "../ScrollHelper.ts";

    export default {
        name: "MobileNavigation",
        props: {
            data: Object,
            closeNavigation: Function
        },
        methods: {
            scrollTo: ScrollHelper.scrollTo
        }
    };
</script>

<style lang="scss" scoped>
    section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #f0f0f0;
        padding: 20px 0;
    }

    .grid-container {
        width: 80%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 10px;
    }

    .entry {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 15px;
        height: 50px;

    }
</style>