<template>
    <section>
        <Headline v-bind:headline="this.headline"/>
        <img v-bind:src="url" alt="Welcome Picture">
        <h2>{{title.toUpperCase()}}</h2>
        <div class="paragraph">{{paragraph}}</div>
    </section>
</template>

<script>
    import Headline from "./Headline.vue";

    export default {
        name: "Section",
        components: {
            Headline
        },
        props: {
            headline: String,
            url: String,
            title: String,
            paragraph: String
        }
    };
</script>

<style scoped>
    section {
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    h2 {
		all: unset;
        font-weight: bold;
        font-size: 26px;
        margin-bottom: 30px;
        letter-spacing: 2px;
    }

    .paragraph {
        font-size: 15px;
        letter-spacing: 1.4px;
        line-height: 1.5;
    }

    img {
        margin-bottom: 20px;
        width: 100%;
    }
</style>
