<template>
    <section>
        <div class="content-container">
            <Headline :headline="data.title"></Headline>
            <div class="outer-box" v-for="(price, index) in data.priceBoxes" :key="index">
                <div class="price-box">
                    <div class="price-detail">
                        <span>{{price.priceTitle}}</span>
                        <span>{{price.amount}}</span>
                    </div>
                    <div class="price-desc">{{price.description}}</div>
                </div>
                <div v-if="price.reminder" class="reminder-box">
                    {{price.reminder}}
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import Headline from "../RepeatableElements/Headline.vue";

    export default {
        name: "Prices",
        components: {
            Headline
        },
        props: {
            data: Object,
        }
    }
</script>

<style lang="scss" scoped>
    section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .content-container {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
    }

    .outer-box {
        width: 100%;
        border: 2px solid rgb(222, 200, 80);
        margin-bottom: 10px;
    }

    .price {
        &-box {
            padding: 6px 12px 12px 6px;
        }

        &-detail {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;

            > span {
                font-weight: bold;
            }
        }

        &-desc {
            font-size: 14px;
        }
    }

    .reminder-box {
        text-align: center;
        background-color: rgb(222, 200, 80);
        padding: 4px;
    }
</style>
