<template>
    <div class="section">
        <div class="container">
            <h1>{{this.headline.toUpperCase()}}</h1>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Navigation",
        props: {
            headline: String
        }
    };
</script>

<style lang="scss" scoped>
    .section {
        position: relative;
        margin: 10px 0;
        width: 100%;
    }

    .container {
        width: 100%;

        &:before {
            content: "";
            position: absolute;
            width: 3px;
            height: 30px;
            top: 7px;
            background-color: rgb(222, 200, 80);
            border-radius: 2px;
        }
    }

    h1 {
		all: unset;
        font-size: 40px;
        padding-left: 8px;
    }
</style>
