<template>
    <div class="divider"></div>
</template>

<script>
    export default {
        name: "Navigation"
    };
</script>

<style scoped>
    .divider {
        height: 2px;
        max-width: 1080px;
        width: 80%;
        background-color: #ccc;
        border-radius: 2px;
        margin: 20px 0;
    }

    @media screen and (max-width: 425px) {
        .divider {
            width: 80%;
        }
    }
</style>
