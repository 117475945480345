<template>
    <a href="tel:0170 5695653">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="32" height="32"
             viewBox="0 0 172 172"
             style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#dcc850"><path d="M133.6256,0h-95.25121c-2.92007,0 -5.29747,2.40324 -5.29747,5.375v161.25c0,2.97176 2.3774,5.375 5.29747,5.375h95.25121c2.92007,0 5.29747,-2.40324 5.29747,-5.375v-161.25c0,-2.97176 -2.3774,-5.375 -5.29747,-5.375zM46.30769,18.3732c0,-2.84255 2.55829,-5.14243 5.65926,-5.14243h68.04026c3.1268,0 5.6851,2.29988 5.6851,5.14243v82.33053c0,2.84255 -2.55829,5.14243 -5.65926,5.14243h-68.0661c-3.10096,0 -5.65926,-2.29988 -5.65926,-5.14243zM76.07692,139.87921v-14.1869h24.80769v14.1869zM100.88462,144.58233v14.1869h-24.80769v-14.1869zM78.97115,115.76923c0,-3.92788 3.15265,-7.10637 7.02885,-7.10637c3.87621,0 7.02885,3.17849 7.02885,7.10637c0,3.92788 -3.15264,7.10637 -7.02885,7.10637c-3.8762,0 -7.02885,-3.17849 -7.02885,-7.10637zM46.30769,136.72656c0,-6.09856 5.19411,-11.03426 11.55108,-11.03426h13.25661v14.1869h-24.80769zM46.30769,147.73497v-3.15264h24.80769v14.1869h-13.25661c-6.35697,0 -11.55108,-4.9357 -11.55108,-11.03426zM125.69231,147.73497c0,6.09856 -5.16827,11.03426 -11.55108,11.03426h-8.29507v-14.1869h19.84615zM125.69231,139.87921h-19.84615v-14.1869h8.29507c6.38281,0 11.55108,4.9357 11.55108,11.03426z"></path></g></g></svg>
        <div style="display: inline-block">
            <div class="mobile-number" >0170 5695653</div>
            <div class="detail-text">Rund um die Uhr erreichbar!</div>
        </div>
    </a>
</template>

<script>
    export default {
        name: 'ContactTel',
    }
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
        color: white;
    }

    .mobile-number {
        font-size: 20px;
    }

    .detail-text {
        font-size: 14px;
    }

    .icon {
        height: 36px;
        width: 36px;
        margin-right: 10px;
    }
</style>