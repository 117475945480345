<template>
  <section>
    <div class="header">
      <Navigation v-if="!isMobile" />
      <img class="logo" src="../../assets/logo_negativ_ohne_website.png" alt="logo">
      <HeaderBanner :title="title" v-if="!isMobile" />
    </div>
    <HeaderMobileBanner v-if="isMobile" :openNavigation="openNavigation"></HeaderMobileBanner>
  </section>
</template>

<script>
  import Navigation from './Navigation.vue';
  import HeaderBanner from './HeaderBanner.vue';
  import HeaderMobileBanner from './HeaderMobileBanner.vue';

  export default {
    name: 'Header',
    data: function () {
      return {
        isMobile: window.innerWidth <= 720
      }
    },
    components: {
      Navigation,
      HeaderBanner,
      HeaderMobileBanner
    },
    props: {
      title: String,
      openNavigation: Function,
    }
  };
</script>

<style scoped>
  section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .header {
    position: relative;
    height: 487px;
    width: 100%;
    background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5)), url("../../assets/teaser_gross.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .logo {
    position: absolute;
    height: 208px;
    width: 300px;
    top: 50px;
    left: 50px;
  }

  @media screen and (max-width: 425px) {
    .header {
      height: 280px;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo {
      position: static;
      height: 158px;
      width: 250px;
    }
  }
</style>
