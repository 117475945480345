<template>
  <div class="headerBanner">
    <div class="headline">{{title.toUpperCase()}}</div>
    <button class="more" v-on:click="scrollTo('methods')">Mehr erfahren</button>
    <button class="prices" v-on:click="scrollTo('prices')">Preise</button>
  </div>
</template>

<script>
  import ScrollHelper from '../ScrollHelper.ts';

  export default {
    name: 'HeaderBanner',
    methods: {
      scrollTo: ScrollHelper.scrollTo
    },
    props: {
      title: String
    }
  };
</script>

<style lang="scss" scoped>
  .headerBanner {
    position: absolute;
    top: 300px;
    right: 100px;

    &::before {
      content: "";
      position: absolute;
      width: 3px;
      height: 95px;
      background-color: rgb(222, 200, 80);
      left: -10px;
      top: 6px;
    }
  }

  .more:hover {
    background: darken(rgb(222, 200, 80), 10%);
  }

  .prices:hover {
    background: rgb(222, 200, 80);
    color: black;
  }

  .headline {
    color: white;
    width: 600px;
    margin: 0;
    font-size: 46px;
    font-weight: 600;
    letter-spacing: 1.2px;
  }

  .prices {
    background-color: transparent;
    color: rgb(222, 200, 80);
    box-shadow: inset 0px 0px 0px 3px rgb(222, 200, 80);
  }
</style>
