<template>
  <section>
    <div class="content-container">
      <Headline :headline="data.headline"></Headline>
      <div class="stage">
        <img style="width: 260px" :src="require('@/assets/bemer_logo.svg')" alt="bemer logo" />
        <div style="width: 100%; text-align: center" class="paragraph" v-html="data.stageText"></div>
      </div>
      <div class="item-container">
        <div class="paragraph">
          <h2>{{ data.gridItems.left.title.toUpperCase() }}</h2>
          <div v-html="data.gridItems.left.paragraph"></div>
        </div>
        <div v-if="!isTablet" style="display: flex; justify-content: center; align-items: center;">
          <div class="divider"></div>
        </div>
        <div class="paragraph">
          <h2>{{data.gridItems.right.title.toUpperCase()}}</h2>
          <div v-html="data.gridItems.right.paragraph"></div>
          <!--          <img style="border-radius: 50%" :src="data.gridItems.right.img">-->
        </div>
      </div>
      <div style="width: 100%; text-align: center" class="paragraph" v-html="data.bottomText"></div>
    </div>
  </section>
</template>

<script lang="ts">
  import Headline from '../RepeatableElements/Headline.vue'

  export default {
    name: 'RentABemer',
    components: {
      Headline,
    },
    data: function () {
      return {
        isTablet: window.innerWidth <= 720
      }
    },
    props: {
      data: Object
    }
  }
</script>

<style lang="scss" scoped>
  section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #eeeeee;
    margin: 30px 0;
    padding: 10px 0;
  }

  .divider {
    height: 100%;
    width: 2px;
    background-color: #cccccc;
  }

  .flex-container {
    margin: 10px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .item-container {
    margin: 10px 0;
    display: grid;
    grid-template-columns: 1fr 10px 1fr;


    @media screen and (max-width: 720px) {
      grid-template-columns: 1fr;
    }
  }

  .stage {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .paragraph {
    font-size: 15px;
    letter-spacing: 1.4px;
    line-height: 1.5;
    padding: 6px;
  }

  .content-container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
  }

  .outer-box {
    width: 100%;
    border: 2px solid rgb(222, 200, 80);
    margin-bottom: 10px;
  }

  .price {
    &-box {
      padding: 6px 12px 12px 6px;
    }

    &-detail {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      > span {
        font-weight: bold;
      }
    }

    &-desc {
      font-size: 14px;
    }
  }

  .reminder-box {
    text-align: center;
    background-color: rgb(222, 200, 80);
    padding: 4px;
  }
</style>
