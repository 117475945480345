<template>
	<section>
		<div class="content-container">
			<Headline :headline="title"></Headline>
			<div class="description">{{description}}</div>
			<div :class="smallRows ? 'element-container-small' : 'element-container'">
				<div class="element" v-for="(element,index) in content" :key="index">
					<img :src="element.url" :alt="element.keyword">
					<div class="image-banner">
						{{element.keyword.toUpperCase()}}
					</div>
					<div class="paragraph" v-html="element.paragraph"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
	import Headline from './Headline.vue'

	export default {
		name: 'ContentSection',
		components: {
			Headline
		},
		props: {
			title: String,
			description: String,
			content: Array,
			smallRows: Boolean,
		}
	}
</script>

<style lang="scss" scoped>
	section {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	img {
		width: 100%;
	}

	.content-container {
		width: 80%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.image-banner {
		box-sizing: border-box;
		width: 100%;
		text-align: center;
		background-color: #323232;
		color: white;
		font-weight: 500;
		font-size: 18px;
		letter-spacing: 1.4px;
		padding: 4px;
		margin: -4px 0 10px 0;
	}

	.paragraph {
		font-size: 15px;
		letter-spacing: 1.2px;
		line-height: 1.5;
		margin-bottom: 20px;
		word-wrap: break-word;
	}

	.description {
		font-size: 15px;
		letter-spacing: 1.2px;
		line-height: 1.5;
		margin-bottom: 20px;
	}

	@media screen and (min-width: 900px) {
		.element-container {
			width: 100%;
			display: grid;
			grid-template-columns: 50% 50%;
			grid-column-gap: 10px;
		}

		.element-container-small {
			width: 100%;
			display: grid;
			grid-template-columns: 33% 33% 33%;
			grid-column-gap: 10px;
		}

		img {
			width: 100%;
			height: auto;
		}

		.image-banner {
			font-size: 20px;
		}
	}

	@media screen and (max-width: 899px) {
		.element-container-small {
			width: 100%;
			display: grid;
			grid-template-columns: 100%;
			grid-column-gap: 10px;
		}
	}
</style>
