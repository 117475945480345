<template>
  <section>
    <div class="content-container">
      <Headline :headline="data.title"></Headline>
      <div class="grid-container">
        <div class="toggle-box" v-for="(singleBox, index) in data.faqBoxes" :key="index">
          <div :class="['title-bar', openBoxes.indexOf(index) > -1 ? 'active' : '']" v-on:click="toggleBoxes(index)">
            <span class="icon">? //</span>
            {{singleBox.question}}
          </div>
          <div :class="openBoxes.indexOf(index) > -1 ? 'box-show' : 'box-hide'">{{singleBox.answer}}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
  import Headline from '../RepeatableElements/Headline.vue'

  export default {
    name: 'FAQ',
    components: {
      Headline
    },
    data: function () {
      return {
        openBoxes: []
      }
    },
    props: {
      data: Object
    },
    methods: {
      toggleBoxes: function (index) {
        if (this.openBoxes.find((el) => index === el) !== undefined) {
          const i = this.openBoxes.indexOf(index)
          if (i > -1) {
            this.openBoxes.splice(i, 1)
          }
        } else {
          this.openBoxes.push(index)
        }
      }
    }
  }

</script>

<style lang="scss" scoped>
  section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .content-container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 10px;
  }

  .toggle-box {
    transition: all 0.3s ease-out;
    margin-bottom: 10px;
    max-height: 1200px;
  }

  .icon {
    color: rgb(222, 200, 80);
    position: absolute;
    left: 10px;
    font-weight: 600;
  }

  .title-bar {
    position: relative;
    padding: 10px 8px 10px 40px;
    font-size: 18px;
    font-weight: 500;
    vertical-align: middle;
    background: #323232;
    color: white;
    overflow: hidden;
  }

  .active {
    background: #dec850;
  }

  .box-show {
    background: #f0f0f0;
    padding: 10px;
    max-height: 500px;
  }

  .box-hide {
    overflow: hidden;
    max-height: 0;
  }

  @media screen and (max-width: 600px) {
    .grid-container {
      grid-template-columns: 100%;
    }
  }
</style>
