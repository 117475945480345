<template>
  <section>
    <div class="content-container">
      <div style="display: flex; justify-content: center; align-items: center; flex-direction: column">
        <img class="logo" src="../../assets/logo_negativ_ohne_website.png" alt="logo">
        <div class="text">
          Ich bin Pferdeostepathin &- physiotherapeutin und widme mich mit großer Leidenschaft der Gesunderhaltung von Pferden.
        </div>
      </div>
      <div class="contact">
        <div class="contact-title">KONTAKT</div>
        <div class="footer-contact-element">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               width="32" height="32"
               viewBox="0 0 172 172"
               style=" fill:#000000;">
            <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#dcc850">
                <path
                  d="M133.6256,0h-95.25121c-2.92007,0 -5.29747,2.40324 -5.29747,5.375v161.25c0,2.97176 2.3774,5.375 5.29747,5.375h95.25121c2.92007,0 5.29747,-2.40324 5.29747,-5.375v-161.25c0,-2.97176 -2.3774,-5.375 -5.29747,-5.375zM46.30769,18.3732c0,-2.84255 2.55829,-5.14243 5.65926,-5.14243h68.04026c3.1268,0 5.6851,2.29988 5.6851,5.14243v82.33053c0,2.84255 -2.55829,5.14243 -5.65926,5.14243h-68.0661c-3.10096,0 -5.65926,-2.29988 -5.65926,-5.14243zM76.07692,139.87921v-14.1869h24.80769v14.1869zM100.88462,144.58233v14.1869h-24.80769v-14.1869zM78.97115,115.76923c0,-3.92788 3.15265,-7.10637 7.02885,-7.10637c3.87621,0 7.02885,3.17849 7.02885,7.10637c0,3.92788 -3.15264,7.10637 -7.02885,7.10637c-3.8762,0 -7.02885,-3.17849 -7.02885,-7.10637zM46.30769,136.72656c0,-6.09856 5.19411,-11.03426 11.55108,-11.03426h13.25661v14.1869h-24.80769zM46.30769,147.73497v-3.15264h24.80769v14.1869h-13.25661c-6.35697,0 -11.55108,-4.9357 -11.55108,-11.03426zM125.69231,147.73497c0,6.09856 -5.16827,11.03426 -11.55108,11.03426h-8.29507v-14.1869h19.84615zM125.69231,139.87921h-19.84615v-14.1869h8.29507c6.38281,0 11.55108,4.9357 11.55108,11.03426z"></path>
              </g>
            </g>
          </svg>
          <a href="tel:0170 5695653">0170 5695653</a>
        </div>
        <div class="footer-contact-element">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               width="32" height="32"
               viewBox="0 0 172 172"
               style=" fill:#000000;">
            <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#dcc850">
                <path d="M86,14.33333c-27.70633,0 -50.16667,22.46033 -50.16667,50.16667c0,28.423 31.562,70.37667 44.61967,86.50883c2.86667,3.54033 8.22733,3.54033 11.094,0c13.05767,-16.13217 44.61967,-58.08583 44.61967,-86.50883c0,-27.70633 -22.46033,-50.16667 -50.16667,-50.16667zM86,82.41667c-9.89717,0 -17.91667,-8.0195 -17.91667,-17.91667c0,-9.89717 8.0195,-17.91667 17.91667,-17.91667c9.89717,0 17.91667,8.0195 17.91667,17.91667c0,9.89717 -8.0195,17.91667 -17.91667,17.91667z"></path>
              </g>
            </g>
          </svg>
          <span>23823 Seedorf + 50km Umkreis</span>
        </div>
        <div class="footer-contact-element">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="32px" height="32px" class="">
						<path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160    C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48    h192c61.76,0,112,50.24,112,112V352z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#dcc850" />
            <path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336    c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#dcc850" />
            <circle cx="393.6" cy="118.4" r="17.056" data-original="#000000" class="active-path" data-old_color="#000000" fill="#DEBC57" />
						</svg>
          <a href="https://www.instagram.com/duve_pferdetherapie/" target="_blank">
            <span class="instalink">Besuch mich auf Instagram</span>
          </a>
        </div>
      </div>
    </div>

    <Divider style="opacity: 0.2;"></Divider>
    <div class="copyright">© All rights reserved 2020</div>
  </section>
</template>

<script>
  import Divider from '../VisualSupportElements/Divider';

  export default {
    name: 'Footer',
    components: {
      Divider
    },
    props: {
      content: String
    }
  };
</script>

<style lang="scss" scoped>
  section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #323232;
  }

  img {
    margin-bottom: 10px;
  }

  .content-container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }

  .text {
    color: white;
    margin: 20px 0;
    max-width: 400px;
    text-align: center;
  }

  .logo {
    height: 158px;
    width: 250px;
  }

  .footer-contact-element {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .contact {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      text-decoration: none;
      color: white;
    }

    &-title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1.4px;
      position: relative;
      margin-bottom: 20px;

      &:after {
        position: absolute;
        content: '';
        height: 2px;
        bottom: -2px;
        margin: 0 auto;
        left: 0;
        width: 87px;
        background: rgb(222, 200, 80);
      }
    }
  }

  @media screen and (min-width: 900px) {
    .content-container {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  }

  .copyright {
    color: white;
    margin: 10px 0 30px 0;
  }

  .instalink {
    margin-left: 6px;

    &:hover {
      color: #dcc850;
    }
  }
</style>
